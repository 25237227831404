import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Storage} from '@ionic/storage';
import {EnvService} from './env.service';
import {User} from './models/user/user.module';
import {error} from 'util';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    isLoggedIn = false;
    token: {
        token_type: string;
        access_token: any;
    };
    userObj = null;
    lang = 'en';
    websocketConnectionKey: null;

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private env: EnvService,
    ) {
    }

    login(email: string, password: string) {
        this.token = {
            token_type: '',
            access_token: ''
        };
        return this.http.post(this.env.API_URL + '/api/v1/auth/login',
            {username: email, password}
        ).pipe(
            tap(token => {
                this.token.token_type = 'Bearer';
                // @ts-ignore
                this.token.access_token = token.token;
                // @ts-ignore
                this.websocketConnectionKey = token.websocketConnectionKey;

                this.user();
                this.storage.set('token', this.token)
                    .then(
                        () => {
                            console.log('Token Stored');
                        },
                        // tslint:disable-next-line:no-shadowed-variable
                        error => console.error('Error storing item', error)
                    );

                this.storage.set('websocketConnectionKey', this.websocketConnectionKey)
                    .then(
                        () => {
                            console.log('websocketConnectionKey Stored');
                        },
                        // tslint:disable-next-line:no-shadowed-variable
                        error => console.error('Error storing item', error)
                    );

                this.isLoggedIn = true;
                return token;
            }),
        );
    }

    register(fullName: string, email: string, password: string, terms: boolean, privacy: boolean) {
        return this.http.post(this.env.API_URL + '/api/v1/auth/register',
            {fullName, email, password, terms, privacy}
        );
    }

    user() {
        if (this.token === null) {
            this.userObj = null;
            return null;
        }
        const headers = new HttpHeaders({
            Authorization:  this.token.access_token
        });

        return this.http.get<User>(this.env.API_URL + '/api/v1/user/', {headers})
            .pipe(
                tap(user => {
                    this.userObj = user;
                    return user;
                })
            );
    }

    reloadAuth() {
        const that = this;
        this.getWebsocketConnectionKey();
        return this.storage.get('token').then((data) => {
            return new Promise((resolve, reject) => {
                this.token = data;
                if (this.token != null) {
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
                console.log('token reload done');
                this.getUserLanguage();
                resolve();
            });
        }).then(() => {
            return new Promise((resolve, reject) => {
                try {
                    if (this.token === null) {
                        reject();
                    }
                    const headers = new HttpHeaders({
                        Authorization:  this.token.access_token
                    });

                    this.http.get<User>(this.env.API_URL + '/api/v1/user/', {headers})
                        .subscribe(
                            user => {
                                this.userObj = user;
                                console.log('user reload done');
                                resolve();
                            }, (err) => {
                                console.error(err);
                                reject();
                            });
                } catch (e) {
                    console.warn(e);
                    reject();
                }
            });
        }).catch((e) => {
            console.warn(e);
        });
    }

    getToken() {
        return this.storage.get('token').then(
            data => {
                this.token = data;

                if (this.token != null) {
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            },
            error => {
                this.token = null;
                this.isLoggedIn = false;
            }
        );
    }

    getWebsocketConnectionKey() {
        return this.storage.get('websocketConnectionKey').then(
            data => {
                this.websocketConnectionKey = data;
            },
            error => {
                this.websocketConnectionKey = null;
            }
        );
    }

    clearToken() {
        this.token = null;
        this.isLoggedIn = false;
        this.userObj = null;
        return this.storage.remove('token');
    }

    setUserLanguage(lang) {
        this.storage.set('lang', lang)
            .then(
                () => {
                    console.log('lang stored');
                },
                // tslint:disable-next-line:no-shadowed-variable
                error => console.error('Error storing lang item', error)
            );
    }

    getUserLanguage() {
        return this.storage.get('lang').then(
            data => {
                this.lang = data;
            },
            error => {
                this.lang = 'en';
            }
        );
    }

}
