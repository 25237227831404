import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
      private router: Router,
      private authService: AuthService
  ) {}

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('guard called');
    try {
      return this.authService.reloadAuth().then(() => {
        console.log('check if auth');
        const currentUser = this.authService.isLoggedIn;
        if (currentUser) {
          const tokenObj = this.authService.token;
          if (tokenObj) {
            const accessToken = this.authService.token.access_token;
            if (accessToken) {
              // authorised so return true
              return true;
            }
          }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/']);
        return false;
      }, () => {
        this.router.navigate(['/']);
        return false;
      }).catch((err) => {
        console.error(err);
        this.router.navigate(['/']);
        return false;
      });
    } catch (e) {
      console.error(e);
      this.router.navigate(['/']);
      return false;
    }
  }
}
