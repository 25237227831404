import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  API_URL = 'http://127.0.0.1:8000';
  WEBSOCKET_URL = 'wss://guidemego.com:8000';
  COMPANY_URL = '';
  IS_TEST_SYSTEM = false;
  IS_BERLIN_SYSTEM = false;
  IS_APP_SYSTEM = false;

  constructor(
      public platform: Platform
  ) {
    if (window.location.href.lastIndexOf('guidemego') >= 0) {
      this.API_URL = 'https://guidemego.com/backend/public';
    }
    if (window.location.href.lastIndexOf('-test') > 0) {
      this.API_URL = 'https://guidemego-test.guidemego.com/backend/public';
      this.IS_TEST_SYSTEM = true;
      this.WEBSOCKET_URL = 'wss://guidemego.com:8100';
    }
    if (window.location.href.lastIndexOf('berlin.') > 0) {
      this.API_URL = 'https://berlin.guidemego.com/backend/public';
      this.IS_BERLIN_SYSTEM = true;
      this.WEBSOCKET_URL = 'wss://guidemego.com:8200';
    }
    if (window.location.href.lastIndexOf('app.') >= 0) {
      this.API_URL = 'https://app.guidemego.com/backend/public';
      this.IS_APP_SYSTEM = true;
      this.WEBSOCKET_URL = 'wss://guidemego.com:8000';
    }
    if (window.location.href.lastIndexOf('-berlin') > 0) {
      this.API_URL = 'https://guidemego-berlin.guidemego.com/backend/public';
      this.IS_BERLIN_SYSTEM = true;
      this.WEBSOCKET_URL = 'wss://guidemego.com:8200';
    }
    if (platform.is('android')) {
      this.API_URL = 'https://guidemego.com/backend/public';
      this.COMPANY_URL = 'testenv';
    }
  }
}
