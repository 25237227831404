import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: './login/login.module#LoginPageModule' },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  { path: 'admin', loadChildren: './admin/index/index.module#IndexPageModule', canActivate: [AuthGuard] },
  { path: 'admin/user', loadChildren: './admin/user/user.module#UserPageModule', canActivate: [AuthGuard] },
  { path: 'admin/logs', loadChildren: './admin/logs/logs.module#LogsPageModule', canActivate: [AuthGuard] },
  { path: 'admin/feedbacks', loadChildren: './admin/feedbacks/feedbacks.module#FeedbacksPageModule', canActivate: [AuthGuard] },
  { path: 'admin/company', loadChildren: './admin/company/index/index.module#IndexPageModule', canActivate: [AuthGuard] },
  { path: 'admin/company/edit', loadChildren: './admin/company/edit/edit.module#EditPageModule', canActivate: [AuthGuard] },
  { path: 'system-status', loadChildren: './system-status/system-status.module#SystemStatusPageModule' },
  { path: 'operator', loadChildren: './operator/operator.module#OperatorPageModule', canActivate: [AuthGuard] },
  { path: 'feedback', loadChildren: './operator/feedback/feedback.module#FeedbackPageModule', canActivate: [AuthGuard] },
  { path: 'logout', loadChildren: './logout/logout.module#LogoutPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'register/:company', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'withoutlogin', loadChildren: './withoutlogin/withoutlogin.module#WithoutloginPageModule' },
  { path: 'withoutlogin/:company', loadChildren: './withoutlogin/withoutlogin.module#WithoutloginPageModule' },
  { path: ':company', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'admin/company/polygon/index', loadChildren: './admin/company/polygon/index/index.module#IndexPageModule' },
  { path: 'admin/company/polygon/add', loadChildren: './admin/company/polygon/add/add.module#AddPageModule' },
  { path: 'admin/company/businessHours/index', loadChildren: './admin/company/businessHours/index/index.module#IndexPageModule' },
  { path: 'admin/company/businessHours/add', loadChildren: './admin/company/businessHours/add/add.module#AddPageModule' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
